<template>
  <v-app-bar 
    app
    dark
    color="background"
    :class="['app-bar', 'elevation-'+ (elevate ? '2' : '0') ]"
  >
    <v-btn
      icon
      :color="hasNotifications ? 'primary' : 'white'"
      @click="toggleView('notifications')"
    >
      <v-slide-x-transition mode="out-in">
        <v-badge 
          v-if="hasNotifications"
          dot
          bordered
          offset-x="4"
          offset-y="8"
          :value="view.notify"
          color="primary"
        >
          <v-icon>
            {{ icons.notification }}
          </v-icon>
        </v-badge>
        <icon-base
          v-else
          width="37"
          height="43"
          name="hive"
          class="hive primary--text"
        >
          <brand-symbol />
        </icon-base>
      </v-slide-x-transition>
    </v-btn>

    <v-spacer />

    <v-toolbar-title
      v-if="title"
    >
      <v-toolbar-title class="title text-body-1">
        {{ title }}
      </v-toolbar-title>
    </v-toolbar-title>
    <icon-base
      v-else
      width="126"
      height="26"
      name="Mobees"
      color="primary"
      class="logo"
    >
      <brand-logo />
    </icon-base>

    <v-spacer />

    <v-btn
      icon
      :color="views.profile.pending ? 'warning' : undefined"
      :loading="views.profile.loading"
      @click="toggleView('profile')"
    >
      <v-icon>
        {{ views.profile.pending ? icons.alert : icons.profile }}
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<style>

  .v-app-bar {
    background: var(--v-background-base) !important;
    padding: env(safe-area-inset-top) 0 0 env(safe-area-inset-left) !important;
  }

  .v-app-bar .title {
    font-size: 1.125rem !important;
    font-weight: 500;
    letter-spacing: 0.0625rem !important;
    line-height: 1.75rem;
    opacity: .875;
  }

  .v-app-bar .hive {
    height: 24px;
  }

  .v-app-bar .logo {
    height: 16px;
    /* opacity: .75; */
  }

  /* .v-app-bar .notify {
    animation: pulse 1s;
  } */

  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title, .v-toolbar__extension > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 8px;
  }
  
</style>

<script>
  // Utilities
  import {
    sync,
    get
  } from 'vuex-pathify'

  // Icons
  import {
    mdiAccountCircle,
    mdiAlertCircleOutline,
mdiBell,
mdiBellBadge,
  } from '@mdi/js'
  import _ from 'lodash';
  import services from '@/services.js'
  import { steps } from '@/services/signup'

  import { App } from '@capacitor/app';

  export default {
    name: 'TopBar',

    props: {
      scroll: {
        type: Number,
        default: 0
      },
      height: {
        type: Number,
        default: 0
      },
      installed: {
        type: Boolean,
        default: false
      },
      dev: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      icons: {
        profile: mdiAccountCircle,
        alert: mdiAlertCircleOutline,
        notification: mdiBell,
        unread: mdiBellBadge,
      },
      view: {
        scroll: 0,
        notify: false,
        timer: null
      },
      signup: {
        current: null,
        steps
      }
    }),

    computed: {
      views: sync('app/views'),
      notifications: get('app/views@notifications.items'),
      status: get('user/status'),
      help: sync('help'),
      toast: sync('app/toast'),
      version: get('app/version'),

      title () {
        // return !!this.signup.current ? this.signup.steps[this.signup.current].header : this.status=='ACT' ? 'Ciclo'  : 'Mobees';
        const route = this.$route;
        const title = this.status!=='ACT' || route.name=='home' ? null : route.meta.title;
        return title;
      },

      elevate () {
        const scroll = this.scroll;
        return scroll > 16;
      },

      hasNotifications () {
        return _.some(this.notifications, ['read', null]);
      }

      // drawer: sync('pages/drawer'),
    },

    watch: {
      $route: {
        immediate: true,
        handler ($route) {
          if ($route.name=='Signup') {
            const step = _.has($route.params, 'step') ? $route.params.step : null;
            this.signup.current = step;
          }
        }
      },
      installed: {
        immediate: true,
        handler (v) {
          if (v) {
            // App.addListener('backButton', (AppUrlOpen) => {
            //   // prevent hardware navigation
            //   const params = this.$route.query;
            //   const view = _.get(params, 'view', null);
            //   if (!!view) {
            //     this.$router.push({ query: null });
            //   }else{
            //     App.exitApp();
            //   }
            //   console.log('### backButton', this.$route.path);
            // });
          }
        }
      }
    },

    methods: {
      ...services,

      notify (b) {
        this.view.notify = b;
      },

      // toggleView (view) {
        // this.$router.push({ query: { view }});
        // setTimeout(($) => {
          // $.views[target].toggle = true;
        // }, 250, this);

        // this.trackPage(target);
      // },

      // trackPage (target) {
      //   // track analytics
      //   this.$gtag.pageview({
      //     page: '/'+target,
      //     title: this.views[target].title + ' | Parceiros'
      //   });
      //   console.log(this.views[target].title + ' | Parceiros');
      // },
    },

    mounted () {
      this.views.profile.loading = true;
      setInterval(($) => {
        const b = this.hasNotifications ? !this.view.notify : false;
        $.notify(b);
      }, 1000, this);
    }
  }
</script>
